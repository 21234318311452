export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">معلومات إضافية</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">محدثة</p>
            <p className="additional__description">10.10.2024</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">تم التثبيت</p>
            <p className="additional__description">1347444</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">العمر</p>
            <p className="additional__description">18+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">الحجم</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">الإصدار الحالي</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">المطور</p>
            <p className="additional__description">MelBet - Aviator Casino</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">محدثة</p>
            <p className="additional__description">10.10.2024</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">الحجم</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">تم التثبيت</p>
            <p className="additional__description">1347444</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">الإصدار الحالي</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">العمر</p>
            <p className="additional__description">18+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">المطور</p>
            <p className="additional__description">MelBet - Aviator Casino</p>
          </div>
        </div>
      </div>
    </div>
  );
};
